<template>
	<div>
		<div class="queryBox ">
			<el-button size="mini" type="primary" :icon="disLoading?'el-icon-loading':'el-icon-search'"	@click="getData" :disabled="disLoading">查询</el-button>
		</div>
		<template>
			<el-table size="mini" :max-height="height" :data="tableData" border="" stripe v-loading="loading" style="width: 1200px;" :show-overflow-tooltip="true">
				<el-table-column type="index" label="序号" align="center" width="80px"></el-table-column>
				<el-table-column prop="true_name" label="姓名" align="center"></el-table-column>
				<el-table-column prop="card" label="卡号" align="center"></el-table-column>
				<el-table-column prop="bank" label="银行" align="center"></el-table-column>
				<el-table-column prop="true_quota" label="金额" align="center"></el-table-column>
				<el-table-column label="操作" align="center">
					<template slot-scope="scope">
						<el-link class="mr20" type="warning" @click="setCardTrueQuota(scope.row,1)">不修正</el-link>
						<el-link type="primary" @click="setCardTrueQuota(scope.row,2)">修正</el-link>
					</template>
				</el-table-column>

			</el-table>
		</template>
		<div class="flexX  mt20 pageBox">
		
		</div>
		<el-dialog center class="dialog" top="150px" title="" :visible.sync="dialogVisible" @close="close" width="30%"
		 :close-on-click-modal="closeModal" :title="dialogTitle">
			<div class="formBox" v-loading="dialogloading">
				<div class="">
					<div class="flexX flexcenter mb20">
						<span>姓名：</span><span>{{item.true_name}}</span>
					</div>
					<div class="flexX flexcenter mb20">
						<span>卡号：</span><span>{{item.card}}</span>
					</div>
					<div class="flexX flexcenter mb20">
						<span>银行：</span><span>{{item.bank}}</span>
					</div>
					<div class="flexX flexcenter mb20">
						<span>金额：</span><span>{{item.true_quota}}</span>
					</div>
					<div class="flexX flexcenter mb20">
						<span>卡真实余额：</span>
						<el-input class="flex1" size="mini" type="text" v-model="card_true_quota" placeholder="请输入卡真实余额"></el-input>
					</div>
				</div>
				<div class="textCenter">
					<el-button size="mini" type="primary" @click="sure()">确定</el-button>
					<el-button size="mini" @click="close">关闭</el-button>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import local from '@/util/local'
	import {
		isEmpty,
		isPositiveNumber,
		isMoney,
		isMobile,
		isIncludeHanzi
	} from '@/util/validate'
	import {
		Message
	} from 'element-ui'
	export default {
		data() {
			return {
				loading: false,
				disLoading: false,
				dialogType:"",
				closeModal:false,
				dialogVisible:false,
				dialogloading:false,
				dialogTitle:'',
				height: 0,
				tableData: [],
				pageSizesArr: [20, 50, 100, 200],
				pageSize: 20,
				total: 0,
				page: 1,
				item:{},
				card_true_quota:'',
				

			};
		},
		created() {
			// this.date = [this.$util.timestampToTime(new Date().getTime(), true), this.$util.timestampToTime(new Date().getTime(),true)];
			this.cardTrueQuotaList();
			this.$nextTick(() => {
				let topNav = document.getElementsByClassName('topNav')[0].offsetHeight;
				let queryBox = document.getElementsByClassName('queryBox')[0].offsetHeight;
				let pageBox = document.getElementsByClassName('pageBox')[0].offsetHeight;
				let documentHeight = document.documentElement.clientHeight
				this.height = documentHeight - topNav - 20 - pageBox - 20 - queryBox - 20
			})
		},
		filters: {
			tofixed: function(value) {
				if (value || value == 0) {
					let val = Math.floor(((value * 1) * 100).toPrecision(12))
					return ((val / 100).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
				}
			}
		},
		methods: {
			getData(){
				this.cardTrueQuotaList()
			},
			cardTrueQuotaList(){
				this.disLoading = true
				this.$api.cardTrueQuotaList({}).then(res=>{
					this.disLoading = false
					if(res.status == 1){
						this.tableData = res.data
					}
				}).catch(error=>{
					this.disLoading = false
				})
			},
			setCardTrueQuota(item,state){
				if(state == 2){
					this.item = item,
					this.card_true_quota = ""
					this.dialogVisible = true
				}
				if(state == 1){
					let data = {
						id:item.id,
						state:state
					}
					this.$confirm('确定不修正吗?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						this.sureSetCardTrueQuota(data)
					}).catch(() => {
						this.$message({
							type: 'info',
							message: '已取消操作'
						});
					});
				}
			},
			close(){
				this.item = {}
				this.dialogTitle = ""
				this.dialogType=""
				this.dialogVisible= false;
			},
			sureSetCardTrueQuota(data){
				this.dialogloading = true
				this.$api.setCardTrueQuota(data).then(res=>{
					this.dialogloading = false
					if(res.status == 1){
						this.cardTrueQuotaList();
						this.$message({
							type: 'success',
							message: res.msg
						});
						this.close()
					}
				}).catch(error=>{
					this.dialogloading = false
				})
			},
			sure(){
				if(isEmpty(this.card_true_quota,"请输入修正余额")){
					return
				}
				let data = {
					id:this.item.id,
					state:2,
					card_true_quota:this.card_true_quota
				}
				this.sureSetCardTrueQuota(data)
			},			
		},
		watch: {
			total(val, old) {
				this.$nextTick(() => {
					let topNav = document.getElementsByClassName('topNav')[0].offsetHeight;
					let queryBox = document.getElementsByClassName('queryBox')[0].offsetHeight;
					let pageBox = document.getElementsByClassName('pageBox')[0].offsetHeight;
					let documentHeight = document.documentElement.clientHeight
					this.height = documentHeight - topNav - 20 - pageBox - 20 - queryBox - 20
				})
			}
		},
	};
</script>

<style scoped="scoped" lang="scss">
	.formBoxbd {
		width: 80%;
		margin: 0 auto;
	}
</style>
<style type="text/css">
	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}
</style>
